import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  initialize () {
    // this.notyf = new Notyf()
  }

  connect () {
    document.addEventListener('refresh_grid', this.refreshGrid.bind(this))
  }
  
  disconnect () {
    document.removeEventListener('refresh_grid', this.refreshGrid.bind(this))
  }
  
  refreshGrid = event => {
    var msnry = $(".grid").data('masonry');
    msnry.layout();

  }
}