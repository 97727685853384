import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["text", "answerList", "answerErrors"]

  onPostSuccess(event) {
    let [data, status, xhr] = event.detail;
    this.answerListTarget.innerHTML += xhr.response;
    this.textTarget.value = "";
    this.answerErrorsTarget.innerText = "";
  }

  onPostError(event) {
    let [data, status, xhr] = event.detail;
    this.answerErrorsTarget.innerHTML = xhr.response;
  }
}