// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import { RailsUJSController } from 'stimulus-rails-ujs'

// require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "controllers"
import "youtube"
require("trix")
require("@rails/actiontext")
import { Notyf } from 'notyf';

document.addEventListener('my-app:notify', e => {
  let permission
  Notification.requestPermission().then(result => {
    permission = result
    const { title, options, clickUrl } = e.detail
    if (result === 'granted') {
      const notification = new Notification
      notification.onclick = () => window.open(clickUrl)
      notification(title || '', options)
    }
  })
})


