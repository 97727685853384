import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "users", "hiteam_monthly", "hiteam_yearly", "jira_monthly", "jira_yearly", "jira_saving", "asana_monthly", "asana_yearly", "asana_saving", "monday_monthly", "monday_yearly", "monday_saving", "hiteam_per_user_cost", "jira_per_user_cost", "asana_per_user_cost", "monday_per_user_cost"]

  connect() {
    this.calculate();
  }
  
  calculate() {

    var number_of_users = parseInt(this.usersTarget.value)
    var hiteam_yearly = 0;

  // One Fixed Pricing
  if(number_of_users <= 15) {
    this.hiteam_monthlyTarget.textContent = `$19`;
    this.hiteam_yearlyTarget.textContent = `$183`;
    hiteam_yearly = 183;
    this.hiteam_per_user_costTarget.textContent = `$${Math.round((hiteam_yearly/12)/15)}`
  }else if(number_of_users <= 50) {
    this.hiteam_monthlyTarget.textContent = `$49`;
    this.hiteam_yearlyTarget.textContent = `$471`;
    hiteam_yearly = 471;
    this.hiteam_per_user_costTarget.textContent = `$${Math.round((hiteam_yearly/12)/25)}`
  }else if(number_of_users <= 100) {
    this.hiteam_monthlyTarget.textContent = `$99`;
    this.hiteam_yearlyTarget.textContent = `$951`;
    hiteam_yearly = 951;
    this.hiteam_per_user_costTarget.textContent = `$${Math.round((hiteam_yearly/12)/50)}`
  }else if(number_of_users > 100) {
    // this.hiteam_monthlyTarget.textContent = `$199`;
    // this.hiteam_yearlyTarget.textContent = `$1911`;
    // hiteam_yearly = 1911;
    this.hiteam_monthlyTarget.textContent = `$499`;
    this.hiteam_yearlyTarget.textContent = `$4791`;
    hiteam_yearly = 4791;
    this.hiteam_per_user_costTarget.textContent = `$${Math.round((hiteam_yearly/12)/number_of_users)}`
  } else {
    this.hiteam_monthlyTarget.textContent = ``;
    this.hiteam_yearlyTarget.textContent = ``;
    hiteam_yearly = 0;
  }


    // HITEAM Fixed Price
    if(number_of_users <= 15) {
      this.hiteam_monthlyTarget.textContent = `$19`;
      this.hiteam_yearlyTarget.textContent = `$183`;
      hiteam_yearly = 183;
      this.hiteam_per_user_costTarget.textContent = `$${Math.round((hiteam_yearly/12)/15)}`
    }else if(number_of_users <= 50) {
      this.hiteam_monthlyTarget.textContent = `$49`;
      this.hiteam_yearlyTarget.textContent = `$471`;
      hiteam_yearly = 471;
      this.hiteam_per_user_costTarget.textContent = `$${Math.round((hiteam_yearly/12)/25)}`
    }else if(number_of_users <= 100) {
      this.hiteam_monthlyTarget.textContent = `$99`;
      this.hiteam_yearlyTarget.textContent = `$951`;
      hiteam_yearly = 951;
      this.hiteam_per_user_costTarget.textContent = `$${Math.round((hiteam_yearly/12)/50)}`
    }else if(number_of_users > 100) {
      // this.hiteam_monthlyTarget.textContent = `$199`;
      // this.hiteam_yearlyTarget.textContent = `$1911`;
      // hiteam_yearly = 1911;
      this.hiteam_monthlyTarget.textContent = `$499`;
      this.hiteam_yearlyTarget.textContent = `$4791`;
      hiteam_yearly = 4791;
      this.hiteam_per_user_costTarget.textContent = `$${Math.round((hiteam_yearly/12)/number_of_users)}`
    } else {
      this.hiteam_monthlyTarget.textContent = ``;
      this.hiteam_yearlyTarget.textContent = ``;
      hiteam_yearly = 0;
    }

    // HITEAM Per user
    // var hitema_per_user_cost = 10;
    // this.hiteam_monthlyTarget.textContent = `$${number_of_users * hitema_per_user_cost}`;
    // this.hiteam_yearlyTarget.textContent = `$${number_of_users * hitema_per_user_cost * 12}`;
    // this.hiteam_per_user_costTarget.textContent = `$${hitema_per_user_cost}`;
    // hiteam_yearly = number_of_users * hitema_per_user_cost * 12;

    var jira_per_user_cost = 25;
    this.jira_monthlyTarget.textContent = `$${number_of_users * jira_per_user_cost}`;
    this.jira_yearlyTarget.textContent = `$${number_of_users * jira_per_user_cost * 12}`;
    this.jira_savingTarget.textContent = `You Save $${(number_of_users * jira_per_user_cost * 12) - hiteam_yearly} with HITEAM`;
    this.jira_per_user_costTarget.textContent = `$${jira_per_user_cost}`

    var asana_per_user_cost = 24;
    this.asana_monthlyTarget.textContent = `$${number_of_users * asana_per_user_cost}`;
    this.asana_yearlyTarget.textContent = `$${number_of_users * asana_per_user_cost * 12}`;
    this.asana_savingTarget.textContent = `You Save $${(number_of_users * asana_per_user_cost * 12) - hiteam_yearly} with HITEAM`;
    this.asana_per_user_costTarget.textContent = `$${asana_per_user_cost}`

    var monday_per_user_cost = 16;
    this.monday_monthlyTarget.textContent = `$${number_of_users * monday_per_user_cost}`;
    this.monday_yearlyTarget.textContent = `$${number_of_users * monday_per_user_cost * 12}`;
    this.monday_savingTarget.textContent = `You Save $${(number_of_users * monday_per_user_cost * 12) - hiteam_yearly}  with HITEAM`;
    this.monday_per_user_costTarget.textContent = `$${monday_per_user_cost}`

  }
}