import CableReady from 'cable_ready'
import consumer from "./consumer"

$(function() {
  $('[data-channel-subscribe="organization"]').each(function(index, element) {
    var $element = $(element),
        organization_id = $element.data('organization-id');

    consumer.subscriptions.create(
      {
        channel: "OrganizationChannel",
        organization: organization_id
      },{
      connected() {
        // Called when the subscription is ready for use on the server
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        // Called when there's incoming data on the websocket for this channel
        // start

        const startConfetti = () => {
          setTimeout(function() {
            confetti.start()
          }, 1000); // 1000 is time that after 1 second start the confetti ( 1000 = 1 sec)
        };

        //  Stop

        const stopConfetti = () => {
          setTimeout(function() {
            confetti.stop()
          }, 5000); // 5000 is time that after 5 second stop the confetti ( 5000 = 5 sec)
        };

        startConfetti();
        stopConfetti();
        if (data.cableReady) {
          // console.log(data.operations);
          CableReady.perform(data.operations);
        }
      }
    });
  });
});
