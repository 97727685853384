import { Controller } from "@hotwired/stimulus"
import { Notyf } from 'notyf';

export default class extends Controller {

  initialize () {
    this.notyf = new Notyf()
  }

  connect () {
    document.addEventListener('sparkle', this.sparkleUser.bind(this))
  }
  
  disconnect () {
    document.removeEventListener('sparkle', this.sparkleUser.bind(this))
  }
  
  sparkleUser  = event => {
    const { msg } = event.detail
    this.notyf.success({
      message: msg,
      duration: null,
      dismissible: true,
      ripple: true,
      position: {
        x: 'right',
        y: 'top',
      },
    })

    const startConfetti = () => {
      setTimeout(function() {
        confetti.start()
      }, 1000); // 1000 is time that after 1 second start the confetti ( 1000 = 1 sec)
    };

    //  Stop

    const stopConfetti = () => {
      setTimeout(function() {
        confetti.stop()
      }, 5000); // 5000 is time that after 5 second stop the confetti ( 5000 = 5 sec)
    };

    startConfetti();
    stopConfetti();

  }

}