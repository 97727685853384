// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  static values = {
    handle: String,
    animation: String,
    group: String,
    updateUrl: String
  }

  connect() {
    this.initSortable();
    this.disableSortableOnMobile();
  }

  initSortable() {
    this.sortable = Sortable.create(this.element, {
      animation: this.animationValue || 150,
      handle: this.handleValue || undefined,
      group: this.groupValue || undefined,
      delay: 5,
      delayOnTouchOnly: true,
      touchStartThreshold: 3,
      onEnd: this.end.bind(this)
    });
  }

  disableSortableOnMobile() {
    if (window.innerWidth <= 768) {
      this.sortable.option("disabled", true);
    } else {
      this.sortable.option("disabled", false);
    }
  }

  end(event) {
    let url = event.to.dataset.sortableUpdateUrlValue || event.item.dataset.sortableUpdateUrl || this.updateUrlValue
    let data = new FormData()
    data.append("position", event.newIndex + 1)

    if(event.to.dataset.theme_id != undefined) {
      data.append("theme_id", event.to.dataset.theme_id)
    }
    if(event.to.dataset.release_id != undefined) {
      data.append("release_id", event.to.dataset.release_id)
    }
    if(event.to.dataset.track != undefined) {
      data.append("track", event.to.dataset.track)
    }
    if(event.to.dataset.epic_id != undefined) {
      data.append("epic_id", event.to.dataset.epic_id)
    }

    Rails.ajax({
      url: url,
      type: 'PATCH',
      data: data
    })
  }
}