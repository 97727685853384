import CableReady from 'cable_ready'
import consumer from "./consumer"

$(function() {
  $('[data-channel-subscribe="team"]').each(function(index, element) {
    var $element = $(element),
        team_id = $element.data('team-id'),
        daily_plan = $element.data('current-user-daily-plan');

    consumer.subscriptions.create(
      {
        channel: "TeamChannel",
        team: team_id
      },{
      connected() {
        // Called when the subscription is ready for use on the server
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        // Called when there's incoming data on the websocket for this channel

        if (data.cableReady) {
          if(daily_plan == "true") {
            data.operations.outerHtml = data.operations.outerHtml.replace(/remote: true/g, 'data: { modal: true }')
          }
          CableReady.perform(data.operations);
          var msnry = $('.grid').data('masonry');
          if(msnry){
            msnry.layout();
          }
        }
      }
    });
  });
});
