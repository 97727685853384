// app/javascript/controllers/countdown_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["time"];

  connect() {
    const startAt = parseInt(this.data.get("start-at"), 10); // Get the start time
    var endAt = parseInt(this.data.get("end-at"), 10); // Get the end time

    if (isNaN(endAt) || isNaN(startAt)) {
      console.error("Start or end time is not valid.");
      return;
    }

    // If endAt is less than startAt, assume the endAt is on the next day
    if (endAt < startAt) {
      // Add 24 hours (in milliseconds) to the endAt timestamp to indicate next day
      endAt += 24 * 60 * 60 * 1000;
    }

    this.startCountdown(endAt);
  }

  startCountdown(endAt) {
    const countdownInterval = setInterval(() => {
      const now = new Date().getTime();
      const distance = endAt - now;

      if (distance <= 0) {
        clearInterval(countdownInterval);
        this.timeTarget.textContent = "- Time's up!";
        const button = document.getElementById("checkout-button");
        button.style.backgroundColor = `rgb(255, 0, 0)`; // Full red background when time's up
        button.style.color = `rgb(255, 255, 255)`; // White text when time's up
        return;
      }

      // Calculate remaining time: days, hours, minutes, and seconds
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Display remaining time: if there are days left, show them
      let timeString = '';
      if (days > 0) {
        timeString += `${days}d `;
      }
      timeString += `${hours}h ${minutes}m ${seconds}s`;

      // Update the countdown text on the button
      this.timeTarget.textContent = `- ${timeString}`;

      // Optionally update button color as the time progresses
      // this.updateButtonColor(distance, endAt);
    }, 1000);
  }

  updateButtonColor(distance, endAt) {
    const button = document.getElementById("checkout-button");

    const totalDuration = endAt - (new Date().setHours(0, 0, 0, 0)); // total time span
    const progressRatio = 1 - (distance / totalDuration);

    const green = Math.floor((1 - progressRatio) * 255);
    const red = Math.floor(progressRatio * 255);

    button.style.backgroundColor = `rgb(${red}, ${green}, 0)`;
  }
}